<template>
  <div class="">
    <heade></heade>
    <div class="com" :style="{
      backgroundImage: 'url(' + require('@/assets/' + backgroud) + ')',
    }">
      <div class="com_box">
        <div>
          <div class="com-left">
            <div class="com-left-top">{{ practices.practice_course_name }}</div>
            <div class="com-left-foot">
              <div v-for="(items, indexs) in practices.division_category" :key="indexs" class="nice-scroll">
                <router-link v-for="(itemst, indexst) in items.division" :key="indexst" :to="{
                  path: detailPath,
                  query: {
                    id: itemst.division_content_id,
                    division: itemst.id,
                  },
                }" :class="queryWhere.id == itemst.division_content_id
                  ? 'com-left-foot-but active'
                  : 'com-left-foot-but'
                  ">
                  {{ itemst.name }}
                </router-link>
              </div>
            </div>
          </div>
          <div class="com-right">
            <ul class="com-right-div">
              <li v-for="(item, index) in practices.practice_tab" :key="index" :class="{ active: selected == index }"
                @mouseenter="change(index)">
                {{ item.name }}
              </li>
            </ul>
            <div class="content">
              <div class="com-right-s" v-for="(item, index) in practices.practice_tab" :key="index"
                :class="{ actived_content: selected == index }">
                <div v-if="item.is_container_child == 0">
                  <div v-html="item[item.field_name]"></div>
                </div>

                <div v-if="item.is_container_child == 1" style="position: relative; z-index: 1000">
                  <ul class="division_ul" v-for="(items, indexs) in item.category" :key="indexs">
                    <li v-for="(divisions, division_index) in items.division" :key="division_index" :class="item.is_video_list == 1 ? 'container_video_list' : ''
                      ">
                      <div class="main_content" v-if="
                        divisions.from_url == '' &&
                        divisions.link_url == '' &&
                        item.is_video_list == 1
                      " @click="
                        mainContent(
                          divisions.division_content_id,
                          divisions.course_vid,
                          divisions.name
                        )
                        ">
                        <img src="" alt="" />
                        <div class="main_content_title">
                          {{
                            divisions.course_vid == ""
                              ? divisions.name + "（主阅读）"
                              : divisions.name + "（微课）"
                          }}
                        </div>
                      </div>
                      <!-- 视频资料 -->
                      <div v-if="
                        divisions.link_url != '' && item.is_video_list == 0
                      " @click="go(divisions.link_url)">
                        {{ division_index + 1 }}. {{ divisions.name }}
                      </div>

                      <!-- 辅助阅读 -->
                      <div v-if="
                        divisions.link_url == '' && item.is_video_list == 0
                      ">
                        <router-link :to="{
                          path: pathUrl,
                          query: { id: divisions.division_content_id },
                        }">{{ division_index + 1 }}.{{ divisions.name }}
                        </router-link>
                      </div>
                    </li>
                  </ul>
                </div>

                <!-- 装饰图 -->
                <div class="practice-tab-decorate" v-if="
                  item.decorate_is_show == 1 &&
                  item.decorate_is_show != '' &&
                  item.decorate_is_show != null
                ">
                  <img :style="'opacity:' + item.decorate_opacity" :src="item.decorate" />
                </div>
                <div class="practice-tab-background" v-if="
                  item.background_is_show == 1 &&
                  item.background != '' &&
                  item.background != null
                ">
                  <img :style="'opacity:' + item.background_opacity" :src="item.background" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="com-btt">
          <div>
            <div>
              <router-link v-for="(item, index) in practiceTheme" :key="index" :to="{
                path: path,
                query: { id: item.id },
              }" :class="item.id == practices.practice_course_id
                ? 'com-bbt-item active'
                : 'com-bbt-item'
                ">
                {{ item.name }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>
<script>
import "@css/ico.css";
import "@css/sanji.css";
import "@css/foot.css";
import $ from "jquery";
import heade from "@/components/heade.vue";
import foot from "@/components/foot.vue";
import { scroll } from "@utils/scroll";
import { getPracticeTabs, getPracticeCoursesByModularId } from "@api/public";
export default {
  components: {
    heade,
    foot,
  },
  watch: {
    $route(to, from) {
      this.$router.go(0);
    },
    practices() {
      this.$nextTick(function () {
        scroll($(".nice-scroll"));
      });
    },
  },
  props: {},
  data() {
    return {
      selected: 0,
      practices: {},
      queryWhere: {},
      modularQueryWhere: {},
      content: "",
      practiceTheme: [],
      backgroud: "",
      detailPath: "",
      path: "",
      pathUrl: "",
    };
  },
  mounted: function () {
    let that = this;
    //调用接口的方法
    getPracticeTabs(that.queryWhere).then(function (res) {
      that.$set(that, "practices", res.data);
    });

    getPracticeCoursesByModularId(that.modularQueryWhere).then(function (res) {
      that.$set(that, "practiceTheme", res.data);
    });
  },
  created: function () {
    this.queryWhere = this.$route.query;
    this.modularQueryWhere.modular_id = this.$route.meta.id;
    this.detailPath = this.$route.meta.detailPath;
    this.path = this.$route.meta.path;
    this.pathUrl = this.$route.meta.pathUrl;
    this.backgroud = this.$route.meta.background;
    document.title = this.$route.meta.title;
  },
  methods: {
    change(index) {
      this.selected = index;
    },
    go(src) {
      window.location.href = src;
    },
    mainContent: function (divisionContentId, courseVid, title) {
      if (courseVid != "") {
        layui.use(["layer"], function () {
          let layer = layui.layer;
          layer.open({
            title: false,
            type: 2,
            closeBtn: 1,
            anim: 0,
            fixed: true,
            area: courseVid != "" ? ["40%", "50%"] : ["80%", "80%"],
            shadeClose: true,
            content: "/video/singlePlayVideo?id=" + divisionContentId,
            end: function () {
              $(".chose-register").hide();
            },
          });
        });
      } else {
        window.location.href = this.pathUrl + "?id=" + divisionContentId;
      }
    },
  },
  updated: function () {
    layui.use(["table"], function () {
      var table = layui.table;
    });
  },
  destroyed: function () {
    $(".nice-scroll").getNiceScroll().hide();
    $(".nicescroll-cursors").hide();
    $(".nicescroll-rails").remove();
  },
};
</script>
<style scoped>
.com {
  width: 100%;
  min-width: 1484px;
  height: 1660px;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
}

.com_box {
  width: 1484px;
  height: 1660px;
  position: relative;
  margin: 0 auto;
}

.com-left-com-item {
  width: 100%;
}

.com-right-div {
  position: relative;
  height: 56px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  cursor: pointer;
  background: url(../../assets/images/activity_practice_course_tab_bg.png) no-repeat;
  background-size: 100% 56px;
  border-radius: 10px;
  left: -5px;
}

.com-right-div li {
  position: relative;
  line-height: 56px;
  font-size: 16px;
  width: 100px;
  text-align: center;
}

.active {
  color: #900c0c;
}

.com-right-div li.active {
  background: url(../../assets/images/activity_practice_course_tab_title_bg.png) no-repeat;
  background-size: 100% 40px;
  background-position: center center;
}

.com-right-s {
  display: none;
}

table {
  margin: 15px !important;
}

.com_right {
  overflow-y: auto;
}

.com-btt>div {
  width: 15px;
}

.com-btt {
  width: 940px;
  font-size: 14px;
}

.com-btt>div>div {
  width: 638px;
  height: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  left: 193px;
}

.com_right {
  overflow-y: auto;
}

.com-left-foot {
  height: 500px;
  overflow-y: auto;
}

/* 隐藏滚动条 */
.com-left-foot::-webkit-scrollbar {
  display: none;
}

.com-bbt-item {
  width: 15px;
  font-size: 18px;
  line-height: 20px;
}

.com-right-but {
  width: 100%;
  height: 658px;
}

.active2 {
  color: red;
}

.actived_content {
  display: block;
}

.division_ul {
  /* display: flex; */
  justify-content: space-between;
  margin: 40px 30px;
  z-index: 1000;
}

.division_ul li {
  cursor: pointer;
  font-size: 15px;
  display: block;
}

.division_ul li+li {
  margin-top: 40px !important;
}

.division_ul li.container_video_list {
  margin-top: 20px !important;
}

.container_video_list {
  position: relative;
  width: 170px;
  height: 80px;
  background: rgba(255, 255, 255, 0.4);
  text-align: center;
  border-radius: 10px;
  float: left;
  /* overflow: hidden; */
}

.container_video_list * {
  color: #444 !important;
}

.container_video_list+.container_video_list {
  margin-left: 10px;
}

.container_video_list:nth-child(6n) {
  margin-top: 15px;
  margin-left: 0px !important;
}

.container_video_list:hover {
  box-shadow: 3px 3px 3px rgba(255, 255, 255, 0.4);
}

.division_ul li .main_content {
  position: relative;
  border-radius: 10px;
  height: 100%;
}

.division_ul li div {
  color: #333;
}

.division_ul li .main_content+.main_content {
  margin-left: 15px;
  float: left;
}

.division_ul li .main_content img {
  position: relative;
}

.division_ul li .main_content .main_content_title {
  position: relative;
  margin: auto 3px;
}

.content {
  overflow-x: hidden;
  overflow-y: auto;
  height: 90%;
}

.practice-tab-background {
  position: absolute;
  width: 100%;
  height: auto;
  bottom: 0;
  overflow-x: hidden;
  z-index: 0;
}

.practice-tab-decorate {
  position: absolute;
  width: 250px;
  height: auto;
  right: 20px;
  top: 150px;
  overflow-x: hidden;
  z-index: 0;
}

.practice-tab-decorate img,
.practice-tab-background img {
  position: relative;
  width: 100%;
  height: auto;
}

.content::-webkit-scrollbar {
  display: none;
}

.com-left-foot-but.active {
  color: #d8c8a5;
}

.com-right {
  overflow-y: hidden;
  overflow-x: hidden;
}

.com-right .content p>span {
  font-size: 16px !important;
}
</style>
