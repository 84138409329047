var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('heade'),_c('div',{staticClass:"com",style:({
    backgroundImage: 'url(' + require('@/assets/' + _vm.backgroud) + ')',
  })},[_c('div',{staticClass:"com_box"},[_c('div',[_c('div',{staticClass:"com-left"},[_c('div',{staticClass:"com-left-top"},[_vm._v(_vm._s(_vm.practices.practice_course_name))]),_c('div',{staticClass:"com-left-foot"},_vm._l((_vm.practices.division_category),function(items,indexs){return _c('div',{key:indexs,staticClass:"nice-scroll"},_vm._l((items.division),function(itemst,indexst){return _c('router-link',{key:indexst,class:_vm.queryWhere.id == itemst.division_content_id
                ? 'com-left-foot-but active'
                : 'com-left-foot-but',attrs:{"to":{
                path: _vm.detailPath,
                query: {
                  id: itemst.division_content_id,
                  division: itemst.id,
                },
              }}},[_vm._v(" "+_vm._s(itemst.name)+" ")])}),1)}),0)]),_c('div',{staticClass:"com-right"},[_c('ul',{staticClass:"com-right-div"},_vm._l((_vm.practices.practice_tab),function(item,index){return _c('li',{key:index,class:{ active: _vm.selected == index },on:{"mouseenter":function($event){return _vm.change(index)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0),_c('div',{staticClass:"content"},_vm._l((_vm.practices.practice_tab),function(item,index){return _c('div',{key:index,staticClass:"com-right-s",class:{ actived_content: _vm.selected == index }},[(item.is_container_child == 0)?_c('div',[_c('div',{domProps:{"innerHTML":_vm._s(item[item.field_name])}})]):_vm._e(),(item.is_container_child == 1)?_c('div',{staticStyle:{"position":"relative","z-index":"1000"}},_vm._l((item.category),function(items,indexs){return _c('ul',{key:indexs,staticClass:"division_ul"},_vm._l((items.division),function(divisions,division_index){return _c('li',{key:division_index,class:item.is_video_list == 1 ? 'container_video_list' : ''},[(
                      divisions.from_url == '' &&
                      divisions.link_url == '' &&
                      item.is_video_list == 1
                    )?_c('div',{staticClass:"main_content",on:{"click":function($event){return _vm.mainContent(
                        divisions.division_content_id,
                        divisions.course_vid,
                        divisions.name
                      )}}},[_c('img',{attrs:{"src":"","alt":""}}),_c('div',{staticClass:"main_content_title"},[_vm._v(" "+_vm._s(divisions.course_vid == "" ? divisions.name + "（主阅读）" : divisions.name + "（微课）")+" ")])]):_vm._e(),(
                      divisions.link_url != '' && item.is_video_list == 0
                    )?_c('div',{on:{"click":function($event){return _vm.go(divisions.link_url)}}},[_vm._v(" "+_vm._s(division_index + 1)+". "+_vm._s(divisions.name)+" ")]):_vm._e(),(
                      divisions.link_url == '' && item.is_video_list == 0
                    )?_c('div',[_c('router-link',{attrs:{"to":{
                        path: _vm.pathUrl,
                        query: { id: divisions.division_content_id },
                      }}},[_vm._v(_vm._s(division_index + 1)+"."+_vm._s(divisions.name)+" ")])],1):_vm._e()])}),0)}),0):_vm._e(),(
                item.decorate_is_show == 1 &&
                item.decorate_is_show != '' &&
                item.decorate_is_show != null
              )?_c('div',{staticClass:"practice-tab-decorate"},[_c('img',{style:('opacity:' + item.decorate_opacity),attrs:{"src":item.decorate}})]):_vm._e(),(
                item.background_is_show == 1 &&
                item.background != '' &&
                item.background != null
              )?_c('div',{staticClass:"practice-tab-background"},[_c('img',{style:('opacity:' + item.background_opacity),attrs:{"src":item.background}})]):_vm._e()])}),0)])]),_c('div',{staticClass:"com-btt"},[_c('div',[_c('div',_vm._l((_vm.practiceTheme),function(item,index){return _c('router-link',{key:index,class:item.id == _vm.practices.practice_course_id
              ? 'com-bbt-item active'
              : 'com-bbt-item',attrs:{"to":{
              path: _vm.path,
              query: { id: item.id },
            }}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)])])])]),_c('foot')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }